<template>
    <Layout class="knowledgeBaseArticleLayout">
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/knowledge-base/inner`"
        />

        <div class="pageContent">
            <div class="knowledgeBaseArticle">
                <div class="knowledgeBaseArticle__content">
                    <div class="knowledgeBaseArticle__contentText">
                        <div class="singleContent">
                            <h3 style="color: #828EA6;">
                                Начать обучение кажется очень просто, но не все начинают
                                правильно. Как же это сделать? Мы расскажем в данной
                                статье ниже. Готовы?
                            </h3>
                            <img
                                alt="orangeMan"
                                src="@/assets/img/pages/knowledgeBase/knowledgeBaseArticle/orangeMan.png"
                            />
                            <p>
                                Подход к обучению является базисной категорией в методике,
                                дающей представление о взглядах исследователя языка и
                                преподающего язык, как на сам язык, так и на способы
                                овладения им. Он является компонентом системы обучения
                                языку, выступает в качестве самой общей
                                лингводидактической основы овладения языком и дает
                                представление об избранной стратегии знаний, которая
                                служит основанием для выбора методов и приемов обучения.
                            </p>

                            <p>
                                В отечественной методике принято рассматривать три
                                компонента, определяющие подход к обучению:
                                лингвистические, дидактические и психолингвистические
                                основы обучения. В связи с этим говорят о подходе к
                                обучению в узком и широком смысле.
                            </p>
                            <br />

                            <h2>Топ 5 лайфхаков (видео):</h2>

                            <img
                                alt="purpleClock"
                                src="@/assets/img/pages/knowledgeBase/knowledgeBaseArticle/purpleClock.png"
                            />

                            <p>
                                М.В. Ляховицкий называет четыре общих подхода к обучению
                                иностранным языкам:
                            </p>

                            <ul>
                                <li>
                                    Бихевиористский подход, ставящий во главу угла
                                    формирование навыков путем многократного повторения
                                    языкового и речевого материала.
                                </li>
                                <li>
                                    Индуктивно-сознательный подход, когда овладение
                                    языковыми правилами достигается путем интенсивной
                                    работы над многочисленными примерами.
                                </li>
                                <li>
                                    Познавательный (когнитивный) подход, на основе
                                    которого осуществляется прежде всего усвоение теории
                                    изучаемого языка в виде фонетических и грамматических
                                    правил и правил словоупотребления. Этот подход
                                    предполагает конструирование высказываний на
                                    иностранном языке.
                                </li>
                            </ul>

                            <blockquote>
                                И.Б. Ворожцова рассматривает подход как «образовательные
                                программы периода перехода к новой парадигме образования».
                            </blockquote>

                            <p>
                                Исходя из данной характеристики подхода, исследователь
                                выделяет четыре направления подходов к обучению, в
                                соответствии с наиболее выраженной ориентацией обучения на
                                коммуникацию как сущность обучения (коммуникативными),
                                субъектность обучения (личностно-ориентированные),
                                деятельный характер обучения (деятельностные),
                                социокультурный компонент (культурологические).
                            </p>

                            <h3>
                                Вывод: В данном подходе деятельность рассматривается как
                                основной механизм появления новообразований в формировании
                                человека. Новообразования обращены к условиям, при которых
                                деятельность выполняет эти функции.
                            </h3>
                        </div>
                        <div class="knowledgeBaseArticle__contentTextFooterFeedback">
                            <p>
                                Насколько данная статья была полезна?
                            </p>
                            <div class="knowledgeBaseArticle__contentTextFooterSmiles">
                                <router-link
                                    :to="
                                        '/' +
                                            this.$route.params.slug +
                                            '/knowledge-base/inner'
                                    "
                                >
                                    <img
                                        class="knowledgeBaseArticle__contentTextFooterImg"
                                        alt="badSmile"
                                        src="@/assets/img/pages/knowledgeBase/knowledgeBaseArticle/badSmile.png"
                                    />
                                </router-link>
                                <router-link
                                    :to="
                                        '/' +
                                            this.$route.params.slug +
                                            '/knowledge-base/inner'
                                    "
                                >
                                    <img
                                        class="knowledgeBaseArticle__contentTextFooterImg"
                                        alt="normalSmile"
                                        src="@/assets/img/pages/knowledgeBase/knowledgeBaseArticle/normalSmile.png"
                                    />
                                </router-link>
                                <router-link
                                    :to="
                                        '/' +
                                            this.$route.params.slug +
                                            '/knowledge-base/inner'
                                    "
                                >
                                    <img
                                        class="knowledgeBaseArticle__contentTextFooterImg"
                                        alt="badSmile"
                                        src="@/assets/img/pages/knowledgeBase/knowledgeBaseArticle/goodSmile.png"
                                    />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";

export default {
    name: "knowledgeBaseArticle",

    components: {
        Layout,
        PageHeader,
    },

    data() {
        return {
            title: "Гайд для новичков",
            items: [
                {
                    text: "База знаний",
                    to: `/${this.$route.params.slug}/knowledge-base`,
                },
                {
                    text: "С чего начать обучение",
                    to: `/${this.$route.params.slug}/knowledge-base/inner`,
                },
                {
                    text: "Гайд для новичков",
                    active: true,
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import "./knowledgeBaseArticle.scss";
</style>
